.authors #mincards {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  justify-content: center; /* Kartları ortalamak için */
}

.authors #mincards .article-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.authors #mincards .article-item {
  position: relative;
  /* width: 23%; 4 kart yan yana */
  height: 200px; /* Sabit yükseklik */
  margin-bottom: 15px;
  margin-right: 5%;
  cursor: pointer;
  object-fit: cover;
  overflow: hidden; /* Taşan kısımları gizlemek için */
  border-radius: 6px;
}

.authors #mincards .article-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Görselin sabit alana sığmasını sağlamak için */
  /* display: block; */
}

.authors #mincards .article-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 0 0 6px 6px;
}

.authors #mincards .article-category {
  width: 100%;
  background-color: #f73c29;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
}

.authors #mincards .article-title {
  font-size: 16px;
  font-weight: bold;
}

.authors #mincards .article-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #00aaff;
  color: white;
  padding: 2px 5px;
  font-size: 15px;
  border-radius: 7px;
  z-index: 1;
}
@media(max-width:1980px){
  .authors #mincards .article-item {
    width: 300px;
    margin-right: 0%;
  }
}
@media (max-width: 1512px) {
  .authors #mincards .article-item {
    width: 23%;
    height: 250px;
    flex-wrap: wrap;
  }
}
@media (max-width: 1280px) {
  .authors #mincards .article-item {
    width: 25%;
    height: 200px;
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .authors #mincards .article-item {
    width: 37%;
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .authors #mincards {
    flex-direction: column;
    align-items: center;
  }
  .authors #mincards .article-item {
    width: 50%; /* Mobil görünümde genişlik ayarlaması */
    margin: 0 auto 15px;
  }

  .authors .author-content {
    padding-top: 0 !important;
  }
}


@media (max-width: 480px) {
  .authors #mincards .article-category {
    font-size: 13px;
    padding: 3px 7px;
  }

  .authors #mincards .article-label {
    font-size: 13px;
  }

  .authors #mincards .article-item {
    width: 80%;
    height: 250px !important;
  }
}
