.vertical-fixed-right {
  position: absolute;
  top: 340px ;
  right: 0%;
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  padding: 0px;
}

.vertical-fixed-right img {
  max-width: 100%;
  height: 890px;
}

@media (max-width: 1980px) {
  .vertical-fixed-right {
  top: 285px;
  }
  .vertical-fixed-right img {
    height: 900px;
  }
}
@media (max-width: 1920px) {
  .vertical-fixed-right {
  top: 279px !important;
  }
  .vertical-fixed-right img {
    height: 822px;
  }
}
@media (max-width: 1512px) {
  .vertical-fixed-right {
    top: 244px !important;
  }
  .vertical-fixed-right img {
    height: 810px;
  }
}
@media (max-width: 1440px) {
  .vertical-fixed-right {
    width: 190px !important;
    top: 238px !important;
  }
  .vertical-fixed-right img {
    height: 797px;
  }
}
@media (max-width: 1367px) {
  .vertical-fixed-right {
    width: 190px !important;
    top: 230px !important;
  }
  .vertical-fixed-right img {
    height: 775px;
  }
}
@media (max-width: 1280px) {
  .vertical-fixed-right {
    width: 180px !important;
    top: 222px !important;
  }
  .vertical-fixed-right img {
    height: 745px;
  }
}
@media (max-width: 1200px) {
  .vertical-fixed-right {
    top: 215px !important;
  }
  .vertical-fixed-right img {
    height: 540px;
  }
}
@media (max-width: 1024px) {
  .vertical-fixed-right {
  top: 200px !important;
  }
  .vertical-fixed-right img {
    height: 490px;
  }
}
@media (max-width: 991px) {
  .vertical-fixed-right {
    top: 235px !important;
  }
  .vertical-fixed-right img {
    height: 650px;
  }
}
@media (max-width: 835px) {
  .vertical-fixed-right img {
    height: 640px;
  }
  .vertical-fixed-right {
    top: 220px !important;
  }
  .vertical-fixed-left img {
    height: 640px;
  }
  .vertical-fixed-left {
    top: 220px !important;
  }
}
@media (max-width: 820px) {
  .vertical-fixed-right {
    display: none;
  }
}
/* @media (max-width: 800px) {
  .vertical-fixed-right img {
    height: 650px;
  }
  .vertical-fixed-right {
    top: 232px !important;
  }
  .vertical-fixed-left img {
    height: 650px;
  }
  .vertical-fixed-left {
    top: 232px !important;
  }
} */

