.scroller {
  max-width: 1300px;
  margin: 130px auto;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.scroller__inner img {
  width: 250px;
  height: 150px;
  border-radius: 25px;
  object-fit: contain;
  border: 2px solid #a20606;
  padding: 8px;
  box-shadow: 2px 6px 7px rgba(0, 0, 0, 0.7);
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 70s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

@media (max-width: 768px) {
  .scroller__inner img {
    width: 180px;
  }
}
@media (max-width: 576px) {
  .scroller {
    margin: 70px auto;
  }
}
