.contact-top-left-container {
  border-right: 1px solid rgb(209, 209, 209);
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: start;
}

/* .contact-top-container{
    border-bottom: 1px solid rgb(209, 209, 209);
} */

.contact-top-right-container {
  padding-top: 30px;
  padding-bottom: 60px;
  text-align: start;
}

.contact-page-title1 {
  width: 100%;
  color: rgb(31 41 55);
  font-weight: 700;
  font-size: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 15px;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 3px solid rgb(248 113 113);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.contact-page-title2 {
  color: rgb(75 85 99);
  font-size: 16px;
  font-weight: 600;
}

.contact-page-input {
  background-color: #f5f5f5;
  height: 40px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-page-input:focus {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  outline-color: gray;
}

.contact-page-input-message:focus {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  outline-color: rgb(164, 164, 164);
}

.contact-page-input-message {
  background-color: #f5f5f5;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 18px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-page-submit-button {
  font-weight: 700;
  color: white;
  border-radius: 10px;
  background-color: #f87171;
  height: 40px;
  width: 100px;
}

.contact-page-submit-button:hover {
  background-color: #bf3d3d;
}

.contact-page-submit-button:focus {
  background-color: #7f2222;
}

@media (max-width: 1024px) {
  .contact-container,
  .contact-top-container,
  .contact-bottom-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 820px) {
  .contact-container,
  .contact-top-container,
  .contact-bottom-container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .contact-top-container {
    grid: unset;
  }

  .contact-top-left-container {
    border: none;
  }

  .contact-top-right-container {
    padding-left: 0;
  }

  .contact-top-left-container {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .contact-container,
  .contact-top-container,
  .contact-bottom-container {
    padding-left: 15px;
    padding-right: 15px;
    border: none;
  }

  .contact-top-left-container {
    border: none;
  }

  .contact-bottom-container > iframe {
    height: 200px;
  }
}

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup p {
  margin: 0;
}
