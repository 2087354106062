.main-card {
  max-width: 450px;
  font-family: Arial, sans-serif;
  padding: 0px;
  border-radius: 8px;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  height: 275px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: unset;
}

.category-btn {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 3px 7px;
  margin: 3px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 14px;
}

h2 {
  position: absolute;
  bottom: 45px;
  left: 15px;
  font-size: 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 7px;
  border-radius: 4px;
}

.image-container p {
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 3px;
  border-radius: 4px;
  font-size: 12px;
}

.sub-cards {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 15px;
}

.sub-card {
  display: flex;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.sub-card img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
}

.sub-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-card-text h3 {
  margin: 0 0 3px 0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .main-card {
    max-width: 100%;
    /* padding: 0 10px; */
    margin-bottom: 20px;
    padding-top: 1.5rem;
  }

  .image-container {
    height: 250px;
  }

  .sub-card {
    padding: 1px;
  }


  .sub-card img {
    width: 70px;
    height: 70px;
    margin-right: 8px;
  }

  .sub-card-text h3 {
    font-size: 14px;
  }

  .sub-card-text p {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .image-container {
    height: 200px;
  }

  .category-btn {
    font-size: 12px;
    padding: 2px 5px;
  }

  h2 {
    font-size: 16px;
    padding: 5px;
  }

  .image-container p {
    font-size: 10px;
    padding: 2px;
  }

  .sub-card img {
    width: 60px;
    height: 60px;
  }

  .sub-card-text h3 {
    font-size: 14px;
  }

  .sub-card-text p {
    font-size: 9px;
  }
}

/* @media (max-width: 1513px){
  .main-card{
    max-width: 409px;
  }
} */
