.mid-top-ads-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 60px;
}

.mid-top-ads-container a{
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mid-top-ads-container a img {
    width: 100%;
    height: 100%;

}

.mid-bottom-ads-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mid-bottom-ads-container>a>img {
   height: 300px;
    
}

.mid-bottom-ads-container > a:nth-child(2) > img{
    display: none;
}

@media (max-width: 1024px) {
    .mid-top-ads-container a img {
        height: 250px;
        width: 250px;
    }
    .mid-top-ads-container{
        margin-bottom: 5px;
    }
    .mid-bottom-ads-container a img {
        height: 220px;
    }
    
    .mid-bottom-ads-container > a:nth-child(2) > img{
        display: block;
        margin-top: 10%;
        height: 300px;
    }
  }
  @media (max-width: 991px) {
    .reklam2{
        display: none;
    }
    
    .mid-bottom-ads-container > a:nth-child(2) > img{
        display: block;
        margin-top: 10%;
        height: 300px;
    }
    }

  @media (max-width: 820px) {
    .mid-top-ads-container a img {
        height: 250px;
        width: 250px;
        margin-bottom: 5%;
    }

    .mid-bottom-ads-container>a>img {
        height: 250px;
        margin-top: 5% ;
    }

  }

  @media (max-width: 768px) {
    .mid-top-ads-container{
        margin-bottom: 10px;
    }
  
    .mid-bottom-ads-container>a>img {
        height: 200px;
        margin-top: 5% ;
    }

  }
  @media (max-width: 567px) {
    .mid-top-ads-container{
        margin-top: -100px;
        margin-left: 10px;
    }

    .mid-bottom-ads-container>a>img {
        height: 160px !important;
    }
    .mid-bottom-ads-container>a>img {
        height: 280px;
        margin-top: 5% ;
    }
  }  
  @media (max-width: 430px) {
    .mid-bottom-ads-container > a:nth-child(2) > img {
        height: 250px !important;
        margin-bottom: 0px;
    }
  } 
  
  @media (max-width: 390px) {
    .mid-top-ads-container{
        margin-top: -100px;
        margin-left: 10px;
    }

    .mid-bottom-ads-container>a>img {
        height: 160px !important;
    }
    .mid-bottom-ads-container > a:nth-child(2) > img {
        height: 300px !important;
    }
  }   

