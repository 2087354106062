.kny {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 2% 250px;
  /* margin-bottom: 5%; */
}
.kny .member {
   background-color: #fff;/*5f5f5f */
  padding: 3%;
  /* box-shadow: rgba(0, 0, 0, 0.101) 0px 5px 15px; */
}
.kny .member .row {
  /* border: solid 1px red; */
  flex-direction: column;
  padding: 1%;
}
.kny .member .row .containerBox {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.271) 0px 5px 15px;
}
.kny .member .row .col-1 .row {
  width: 100%;
  height: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
}
/* .kny .member .row .col-1 .row .col-6:nth-child(1){
    border-right:solid 1px grey ;
} */
.kny .contentBox {
  object-fit: cover;
}

.kny .contentBox h6 {
  font-weight: bold;
  font-size: large;
}
.kny .kunyeContent {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.kny .mapIcon {
  font-size: 30px;
  margin-top: 0%;
}
.kny .knyTitle{
    font-size: xx-large;
    font-weight: bold;
}
@media (max-width: 1280px) {
  .kny .h6 {
    font-size: medium !important;
  }
  .kny .kunyeContent {
    font-size: 14px;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 img {
    width: 80% !important;
  }
}
@media (max-width: 1200px) {
  .kny .h6 {
    font-size: small !important;
  }

  .kny .kunyeContent {
    font-size: 11px;
  }
}
@media (max-width: 1024px) {
  .kny {
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
  }
  .kny .member {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kny .kunyeContent {
    font-size: 12px;
  }
  .kny .icon {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  .kny {
    height: 100%;
    padding-left: 150px;
    /* margin-bottom: 0%; */
  }
  .kny .member {
    width: 550px;
  }
  .kny .member .row {
    margin-left: 1%;
  }
  .kny .h6 {
    font-size: small !important;
  }
  .kny .kunyeContent {
    font-size: 12px;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 {
    width: 50%;
    padding: 0;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 img {
    width: 70% !important;
  }
  .kny .contentBox {
    padding-top: 1%;
  }
}
@media (max-width: 820px) {
  .kny {
    height: 100%;
    padding: 50px;
    /* border: solid 1px red; */
    margin-top: -10%;
    /* margin-bottom: 0%; */
  }
  .kny .member {
    width: 100%;
  }
  .kny .h6 {
    font-size: small !important;
  }
  .kny .kunyeContent {
    font-size: 12px;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 {
    width: 43%;
    padding: 0;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-6 {
    width: 57%;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 img {
    width: 70% !important;
  }
  .kny .mapIcon {
    font-size: 34px;
    margin-top: -5%;
  }
}
@media (max-width: 768px) {
  .kny {
    height: 100%;
  }
  .kny .member {
    width: 150% !important;
    margin-left: -9%;
  }
  .kny .kunyeContent p{
    font-size: 11px;
  }
  .kny .mapIcon {
    font-size: 20px;
    margin-top: -1%;
  }
}
@media (max-width: 576px) {
  .kny {
    height: 100%;
  }
  .kny .member {
    width: 190% !important;
    margin-left: -120px !important;
  }
  .kny .mapIcon {
    font-size: 34px;
    margin-top: -5%;
  }
}

@media (max-width: 430px) {
  .kny {
    width: 100%;
    padding-left: 1%;
    height: 100%;
  }

  .kny .container {
    width: 420px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1% !important;
  }
  .kny .row .col-1{
    margin-left: -3%;
  }
  .kny .member .row {
    width: 380px !important;
  }
  .kny .row .col-6 .row{
    margin-left: -5%;
  }
  .kny .row .col-6 .row img{
    margin-left: 5%;
  }
  .kny .kunyeContent {
    font-size: 10px;
  }
  .kny .col-5 {
    margin-left: -12%;
    /* border: solid blue 1px; */
  }
  .kny .mapBox {
    margin-left: 0%;
  }
}
@media (max-width: 390px) {

  .kny .row .col-1{
    margin-left: -8%;
  }
  .kny .member .row {
    width: 360px !important;
  }
  .kny .row .col-6 .row{
    margin-left: -5%;
  }
  .kny .row .col-6 .row img{
    margin-left: 5%;
  }
  .kny .mapBox {
    margin-left: 0%;
  }
}
@media (max-width: 360px) {

  .kny .row .col-1{
    margin-left: -14%;
  }
  .kny .member .row {
    width: 330px !important;
  }
  .kny .row .col-6 .row{
    margin-left: -5%;
  }
  .kny .row .col-6 .row img{
    margin-left: 5%;
  }
  .kny .mapBox {
    margin-left: 0%;
  }
  
  .kny .kunyeContent {
    font-size: 8px;
  }
}
