.popUpAds {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);
  z-index: 999;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-top: 8px solid rgb(234, 234, 234);
}

.popUpAds.open {
  transform: translateY(0);
}

.popUpAds.closed {
  transform: translateY(100%);
}

.popUpAds .adContent {
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.popUpAds .adContent.visible {
  opacity: 1;
}

.popUpAds .adContent.hidden {
  opacity: 0;
}


.popUpAds .toggleButton {
  position: absolute;
  left: 10px;
  top: -35px;
  background-color: rgb(234, 234, 234);
  color: #C90913;
  font-weight: bold;
  border: none;
  border-radius: 10px 10px 0 0;
  padding: 3px 20px;
  cursor: pointer;
}

.popUpAds .adContent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.popUpAds .adContent img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .popUpAds .adContent img {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .popUpAds .adContent{
    width: 991px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 835px) {
  .popUpAds .adContent{
    width: 835px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 820px) {
  .popUpAds .adContent{
    width: 820px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 800px) {
  .popUpAds .adContent{
    width: 800px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}  @media screen and (max-width: 768px) {
  .popUpAds .adContent{
    width: 768px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .popUpAds .adContent{
    width: 576px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}
@media screen and (max-width: 430px) {
  .popUpAds .adContent{
    width: 430px !important;
  }
  .popUpAds .adContent img {
    width: 100% !important;
  }
}