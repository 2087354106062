.ptablo .container {
  margin-top: 2%;
  margin-bottom: 4%;
}
.ptablo .th-style {
  background: #2c98f0;
  color: white;
}
.ptablo th,
.ptablo td {
  border: solid 1px white;
}
.ptablo .col-md-12 button {
  margin-left: -12px;
  margin-right: 10px;
}
.ptablo .col-md-12 button:hover {
  background-color: #0d6efd;
}
.ptablo .btnbaslik {
  background-color: #f5f5f5;
}
@media screen and (max-width: 1980px) {
  .ptablo .container {
    margin-top: 32px;
    margin-bottom: 4%;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
}

@media screen and (max-width: 1920px) {
  .ptablo .container {
    margin-top: 27px;
    margin-bottom: 4%;
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}
@media screen and (max-width: 1512px) {
  .ptablo .container {
    margin-top: 28px;
    padding-left: 9% !important;
    padding-right: 9% !important;
  }
}
@media screen and (max-width: 1280px) {
  .ptablo .container {
    margin-top: 22px;
    padding-left: 17% !important;
    padding-right: 17% !important;
  }
}
@media screen and (max-width: 1200px) {
  .ptablo .container {
    margin-top: 22px;
    padding-left: 7% !important;
    padding-right: 7% !important;
  }
}
@media screen and (max-width: 1024px) {
  .ptablo .container {
    margin-top: 18px;
    padding-left: 14% !important;
    padding-right: 14% !important;
  }
}
@media screen and (max-width: 991px) {
  .ptablo .container {
    margin-top: 15px !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .ptablo thead tr th,
  .ptablo tbody tr td {
    font-size: 12px;
  }
}
@media screen and (max-width: 835px) {
  .ptablo .container {
    margin-top: 15px !important;
    padding-left: 18% !important;
    padding-right: 18% !important;
  }
  .ptablo button {
    font-size: 13px;
  }
  .ptablo thead tr th,
  .ptablo tbody tr td {
    font-size: 10px;
  }
}
@media screen and (max-width: 800px) {
  .ptablo .container {
    margin-top: 13px !important;
    padding-left: 21% !important;
    padding-right: 21% !important;
  }
  .ptablo button {
    font-size: 12px;
  }
  .ptablo thead tr th,
  .ptablo tbody tr th {
    font-size: 10px;
  }
}
@media screen and (max-width: 768px) {
  .ptablo .container {
    margin-top: 13px !important;
    padding-left: 6% !important;
    padding-right: 5% !important;
  }
  .ptablo button {
    font-size: 13px;
  }
  .ptablo thead tr th,
  .ptablo tbody tr th {
    font-size: 12px;
  }
}
@media screen and (max-width: 430px) {
  .ptablo .container {
    padding-left: 2% !important;
    padding-right: 1% !important;
  }
  .ptablo button {
    font-size: 11px;
  }
  .ptablo thead tr th,
  .ptablo tbody tr th {
    font-size: 10px;
  }
}
